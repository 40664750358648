import React from 'react';
import { useCommon } from 'models/common';
import { useRouting } from 'models/routing';
import { useMedia } from 'util/hook/useMedia';
import { useLanguage } from 'models/i18n';
import { useOnclickOutside } from 'util/hook/useOnclickOutside';
import { disableBodyScroll } from 'util/helper';
import { Navbar } from 'components/organisms/Navbar';
import Logo from 'components/atoms/Logo';
import Link from 'components/atoms/Link';
import { MenuToggleButton } from 'components/atoms/MenuToggleButton';
import classnames from 'classnames';
import styles from './index.css';

function Header({ t = [] }) {
	const [{ pathname }] = useRouting();
	const media = useMedia();
	const [{ lan }] = useLanguage();
	const [{ activePage }] = useCommon();
	const DONATE_PAGES = ['/donate', '/donate/success', '/donate/welcome', `/donate/${activePage}`];
	const headerRef = React.useRef(null);
	// activeL0 控制 Navbar 顯示（default: Desktop: true / Mobile: false）
	const [activeL0, setActiveL0] = React.useState(() => {
		const isDesktop = window.matchMedia('(min-width: 1280px)').matches;
		return isDesktop;
	});

	// TODO: 暫時先拿掉，當使用者確定連結後要關閉 nav menu
	// React.useEffect(() => {
	// 	const isMobile = window.matchMedia('(max-width: 1279px)').matches;
	// 	if (isMobile) {
	// 		setActiveL0(false);
	// 	}
	// }, [pathname, media]);

	React.useEffect(() => {
		const isMobile = window.matchMedia('(max-width: 1279px)').matches;
		if (isMobile) {
			disableBodyScroll(activeL0);
		}
	}, [activeL0]);

	useOnclickOutside(headerRef, () => setActiveL0(false));

	return (
		<header
			className={classnames(styles.header, {
				[styles.donatePages]: DONATE_PAGES.some(page => `/${lan}${page}` === pathname),
			})}
			ref={headerRef}
		>
			<Link to="/">
				<Logo className={styles.imgLogo} />
			</Link>
			<Navbar activeL0={activeL0} className={styles.navBar} />
			<MenuToggleButton
				active={activeL0}
				className={styles.mobileMenuToggle}
				onClick={() => setActiveL0(prev => !prev)}
			/>
		</header>
	);
}

export default Header;
