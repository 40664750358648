import { setPageLoading } from 'models/common';
import { historyPush } from 'models/routing';
import { createAction, handleActions } from 'redux-actions';
import { wrapFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';

const resetPageState = createAction('RESET_PAGE_STATE');

export const fetchPage = createAction('FETCH_PAGE', id => async dispatch => {
	const { status, message, data } = await wrapFetch(`page/${id}`, {
		method: 'GET',
	});

	if (status !== 200) {
		console.error(message);
	}

	if (!data) {
		dispatch(historyPush({ pathname: '/' }));
	}

	return data;
});

export const initializePageDetail = createAction('INITIALIZE_PAGE_DETAIL', id => async dispatch => {
	dispatch(resetPageState());
	await dispatch(fetchPage(id));
	await dispatch(setPageLoading());
});

const initialState = {
	pageModules: [],
};

const reducer = {
	pageDetail: handleActions(
		{
			RESET_PAGE_STATE: () => ({
				...initialState,
			}),
			FETCH_PAGE_FULFILLED: (state, action) => ({
				...state,
				pageModules: action.payload.page_modules,
			}),
		},
		initialState,
	),
};

const selectPageDetail = state => ({
	...state.pageDetail,
});

export const usePageDetail = () => useRedux(selectPageDetail);

export default { reducer };
